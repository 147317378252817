<template>
<div class="item"  v-if="show" >
  <div v-if="item.tagType=='header'" >
    <label  class="itemTitle" style="white-space: pre-wrap;font-weight: 700">{{item.header}}</label>
  </div>
   <div v-if="item.tagType == 'checkbox'"   >
        <span class="itemTitle">{{item.itemText}}</span>
        <div class="itemOpt">
          <Field :name="name" >
            <template #input>
             <CheckboxGroup v-model="results"   >
               <Checkbox class="line" v-for="(opt ,idx) in opts" :key="idx"  shape="square"  :name="opt.val" >
                 {{opt.text}}
               </Checkbox>
             </CheckboxGroup>
            </template>
          </Field>
        </div>
       <div v-if="item.children.length > 0">
           <div v-for="(childrenItem,index) in item.children" :key="index" >
               <RilinItem :item="childrenItem" :name="'__'+childrenItem.name" mtype="item"  :mdata="childrenItem" :gord="0" :data="data" :showchildren="children" ></RilinItem>
           </div>

       </div>
   </div>
    <div v-if="item.tagType == 'radio'" >
        <span class="itemTitle">{{item.itemText}}</span>
        <div class="itemOpt">
            <Field :name="name" >
                <template #input>
                    <RadioGroup v-model="result.value" >
                        <Radio class="line" v-for="(opt ,idx) in optss" :key="idx"  :name="opt.val" >
                            {{opt.text}}
                        </Radio>
                    </RadioGroup>
                </template>
            </Field>
        </div>
        <div v-if="item.children.length > 0">
            <div v-for="(childrenItem,index) in item.children" :key="index" >
                <RilinItem :item="childrenItem" :name="'__'+childrenItem.name" mtype="item"  :mdata="childrenItem" :gord="0" :data="data" :showchildren="children" ></RilinItem>
            </div>

        </div>
    </div>
    <div v-if="item.tagType == 'slider'" >
        <span class="itemTitle">{{item.itemText}}</span>
        <div class="itemOpt">
            <Field :name="name" >
                <template #input>
                    <Slider v-model="result_slider.value" :step="1" active-color="#ee0a24" :min="minSlider" :max="maxSlider">
                        <template #button>
                            <div class="custom-button">{{ result_slider.value }}</div>
                        </template>
                    </Slider>
                </template>
            </Field>
        </div>
    </div>
   <div v-if="item.tagType == 'select'" >
      <span class="itemTitle">{{item.itemText}}</span>
        <div class="itemOpt">
           <Field 
              readonly
               clickable
               :name="name"
               :value="result.value"
               placeholder="请选择"
               @click="showPicker = true"
              />
          <Popup v-model="showPicker" position="bottom">
              <Picker
               show-toolbar
               :columns="opts"
               @confirm="onConfirm"
               @cancel="showPicker = false"
           />
          </Popup>           
        </div>
   </div>
   <div v-if="item.tagType == 'text' && item.phidden != 1" >
       <span class="itemTitle">{{item.itemText}}</span>
       <div class="itemOpt">
         <Field v-model="result.value" :type="textType"  placeholder="请输入" label-class="labelclass" :name="name"  >
           <template #extra>
             <label>{{ item.units }}</label>
           </template>
         </Field>
       </div> 
   </div>
</div>
</template>

<script>
import {  Checkbox, CheckboxGroup,Field,RadioGroup,Radio ,Popup ,Picker ,Slider } from 'vant';
export default {
  name:'RilinItem',
  components:{
   Checkbox, CheckboxGroup,Field ,RadioGroup, Radio,Popup ,Picker,Slider
  },
  props:{
    item:{
     type: Object,
     required:true
    },
    mdata:{
        type: Object,
    },
    gmdata:{
        type: Object,
    },
    mtype:{
        type: String,
    },
    name:{
      type: String,
      required:true,
    },
    gord:{
      type: Number
    },
    data:{
        type:Object
      },
      showchildren:{
          type: Array
      },
    rules:{
      type: Array
    },
    ruleitems:{
      type: Array
    },
  },
  inject:['drawPic'],
  data() {
    return {
      results:[],
      result:{
          text:'',
          value:''
      },
      result_slider:{
          text:null,
          value:null
      },
      showPicker:false,
      show:false,
      optss:[],
      children:[],
      textType:'text',
      minSlider:null,
      maxSlider:null,
      canvas:{},
      widths:{},
      heights:{},
      sizeRa:null,
    }
  },
  created(){
      // console.log(this.item)
      if(this.item.tagType  == 'radio' || this.item.tagType  == 'checkbox' || this.item.tagType  == 'slider'){
          // console.log("is radio or checkbox")
          let opts=this.item.optionValue.split(",");
          let txts=this.item.optionText.split(",");
          let res=[];
          for(let i=0;i<opts.length;i++){
              if(i==0){
                  this.minSlider = opts[i]
              }else if(i==opts.length -1){
                  this.maxSlider = opts[i]

              }
              res.push({text:txts[i],val:opts[i]});
          }
          this.optss = res;

      }else if(this.item.tagType  == 'text'){
          if(this.item.dataType == "ST"){
              this.textType = 'text'
          }else if(this.item.dataType == "REAL"){
              this.textType = 'number'
          }

      }
      if(this.showchildren!=undefined && this.showchildren.length > 0){
          let isshow = 0;
          for(let i=0;i<this.showchildren.length;i++){
              let tiarr = this.item.tindex.split("][")
              for(let ti of tiarr){
                  let tindex = ti.replace("[","").replace("]","").split("-")[1]
                  if(tindex == this.showchildren[i]){
                      isshow = 1;
                  }
              }
          }
          if(isshow==1){
              this.show = true
          }else{
              this.show = false
          }
      }


  }
  ,
  methods: {
     //select
      watchValue(val,oldval){
          this.data[this.name]={}
          this.data[this.name].value=val
          this.data[this.name].text=this.result.text
          let opts=this.item.optionValue.split(",");
          let ind=opts.indexOf(val);
          if(this.children.indexOf(ind)==-1){
              this.children.push(ind)
          }
          if(this.mtype=='group' && val!=oldval){
              if(this.gmdata.trigger){//值得变化会引起其他值发生变化
                  this.trigger(val);
              }
          }
      },
      changeradio(idx){
          this.result.text = this.optss[idx].text;
          this.watchValue(this.optss[idx].val,"rilintech")
      },
     onConfirm(value){
       this.result.value = value.val;
       this.showPicker = false;
     },
     trigger(value){
       let that=this;
       let gmdata=this.gmdata;
       let ord=this.gord;
       gmdata.trigger.split(",").forEach(function(t) { //item 就是当日按循环到的对象 index是循环的索引，从0开始
                               // let val = t.match(/.+=/)[0].replace("=", "");                              
                                let type = t.match(/=[a-zA-Z0-9_$]+\[/)[0].replace("=","").replace("[", "").toLowerCase();
                                let code = t.match(/\[.+\]/)[0].replace("[", "").replace("]", "");
                                //console.info("触发源",val,gmdata.groupCode+'_'+type+'_'+code+'_'+ord);
                                that.$Event.$emit(gmdata.groupCode+'_'+type+'_'+code+'_'+ord,value);
                              
       });
     },
     triggerby(){
       if(this.mtype=='item' ){
           if(this.showchildren == undefined){
               this.show=true;

           }

       }
     },
    checkRules() {
      // console.log("检查规则")
      // if(this.ruleitems.indexOf(this.item)>-1){
      //   console.log("是计算item")
      // }
      this.ruleitems.find(tempItem => {

        if (tempItem.name === this.item.name) {
          // console.log("是计算item")
          this.rules.forEach(rule => {
            // console.log("准备计算")
            // console.log(rule)

            this.$ruleBus.$emit(rule.itemname, tempItem.name)
          });

        }
      })
    },
  },
  watch:{
        //监听条目是否显示
        show:{
            handler(val){
                this.$Event.$emit('countFillItem',this.name,val);
            }
        },
        // result(val, oldval){//普通的watch监听
        //     this.watchValue(val,oldval)
        // },
        result:{//深度监听，可监听到对象、数组的变化/radio、text结果
            handler(val, oldval){
                this.data[this.name]={}
                this.data[this.name].text=""
                this.data[this.name].value=""

                if(this.item.tagType == 'radio' || this.item.tagType == 'checkbox'){
                    let opts=this.item.optionValue.split(",");
                    let ind=opts.indexOf(val.value);
                    this.children=[]
                    if(this.children.indexOf(ind)==-1){
                        this.children.push(ind)
                    }
                    this.result.text = this.optss[ind].text;
                    this.data[this.name].text=this.result.text
                    if(this.mtype=='group' && val!=oldval){
                        if(this.gmdata.trigger){//值得变化会引起其他值发生变化
                            this.trigger(val);
                        }
                    }
                }
                this.data[this.name].value=val.value
                // console.log(this.data)
              this.checkRules()
            },
            deep:true
        },
      // result_slider:{//深度监听，可监听到对象、数组的变化/slider结果
      //       handler(val,oldval){
      //           this.data[this.name]={}
      //           this.data[this.name].text=val.value
      //           this.data[this.name].value=val.value
      //           if(this.item.tagType == 'slider'){
      //               let opts=this.item.optionValue.split(",");
      //               let ind=opts.indexOf(val.value+'');
      //               this.result_slider.text = this.optss[ind].text;
      //               this.data[this.name].text=this.result_slider.text
      //           }
      //           this.data[this.name].value=val.value
      //           if(this.item.name.indexOf("vas") >-1&&val.value!=oldval.value){
      //               this.drawPic()
      //
      //           }
      //           // console.log(this.data)
      //       },
      //       deep:true
      //   },
      sliderDataNew:{
          handler: function (val, oldval) {
              // console.log(val, oldval);
              this.data[this.name]={}
              this.data[this.name].text=val.value
              this.data[this.name].value=val.value
              if(this.item.tagType == 'slider'){
                  let opts=this.item.optionValue.split(",");
                  let ind=opts.indexOf(val.value+'');
                  this.result_slider.text = this.optss[ind].text;
                  this.data[this.name].text=this.result_slider.text
              }
              this.data[this.name].value=val.value
              if(this.item.name.indexOf("vas") >-1&&val.text==val.value&&val.text!=oldval.text&&oldval.text!=null){
                  // console.log("画图")
                  this.drawPic()
              }
          },
          deep: true
      },
        results:{//深度监听，可监听到对象、数组的变化/checkbox结果
            handler(val){
                let texts = []
                let vals = []
                this.children=[]
                //console.info('this.item.optionValue=>',this.item.optionValue);
                for(let i=0;i<val.length;i++){
                    
                    //console.info('val['+i+']=>',val,val[i]);
                    let opts=this.item.optionValue.split(",");
                    let ind=opts.indexOf(val[i]);

                    if(this.children.indexOf(ind)==-1){
                        this.children.push(ind)
                    }
                    vals.push(val[i])
                    //console.info(this.optss,ind);
                    texts.push(this.optss[ind].text)

                }
                this.data[this.name]={}
                this.data[this.name].value=vals.join(",")
                this.data[this.name].text=texts.join(",")
                 console.log(this.name,this.data)
            },
            deep:true
        },
      showchildren:{//深度监听，可监听到对象、数组的变化
          handler(val){
              let isshow = 0;
              for(let i=0;i<val.length;i++){
                  let tiarr = this.item.tindex.split("][")
                  for(let ti of tiarr){
                      let tindex = ti.replace("[","").replace("]","").split("-")[1]
                      if(tindex == val[i]){
                          isshow = 1;
                      }
                  }
              }
              if(isshow==1){
                  this.show = true
              }else{
                  this.show = false
              }

          },
          deep:true
      },
    },
   mounted() {//在模板编译完成后执行
      // console.log(this.data);
      // console.log(this.item.name);

           if(this.data && this.data['__'+this.item.name] ){
              let datades=this.data['__'+this.item.name];
              // let value="";
              // if(datades.resultMap && datades.resultMap[this.item.name+'_'+this.gord] && datades.resultMap[this.item.name+'_'+this.gord].value){
              //     value=datades.resultMap[this.item.name+'_'+this.gord].value;
              // }
              //  console.log(datades)
              let value = datades.value;
              let text = datades.text;
              let arraytype=["checkbox"];
              let stringtype=["radio","select","text"];
              let slitype=["slider"];
               // console.log(value);

               if(arraytype.indexOf(this.item.tagType)>-1){
                let _results=[];
                value.split(",").forEach(_val=>{
                    if(_val){
                        _results.push(_val);
                    }
                });
                this.results=_results;
                //console.info('init results=>',this.results);
              }
              if(stringtype.indexOf(this.item.tagType)>-1){
                this.result.value=value;
                this.result.text=text;
              }
              if(slitype.indexOf(this.item.tagType)>-1){
                this.result_slider.value=parseInt(value);
                this.result_slider.text=parseInt(text);
              }


              //  console.info("datades.idxs",datades.idxs);
              //   let lg=datades.idxs.length;
              // if( datades.idxs[0]>0 ){
              //     // console.info("触发",this.gmdata.groupCode+'_mord',datades.idxs[lg-1]);
              //      let m=this.$store.state.user.checkMap;
              //      if(!m[this.gmdata.groupCode+'_mord_'+datades.idxs[lg-1]]){//触发一次
              //         m[this.gmdata.groupCode+'_mord_'+datades.idxs[lg-1]]=true;
              //         this.$Event.$emit(this.gmdata.groupCode+'_mord',datades.idxs[lg-1]);
              //      }
              // }
          }
          this.triggerby();  
    },
  computed:{
    opts(){
        let res=[];

        if(this.item.tagType == 'radio' || this.item.tagType == 'checkbox' || this.item.tagType == 'slider'){
            let opts=this.item.optionValue.split(",");
            let txts=this.item.optionText.split(",");
            for(let i=0;i<opts.length;i++){
                res.push({text:txts[i],val:opts[i]});
            }
        }
        // this.optss = res;
        return res;
    },
      sliderDataNew(){
          return JSON.parse(JSON.stringify(this.result_slider));
      }
  }
}
</script>

<style  scoped>
.itemTitle{
   display: block;
   margin-left: 1em;
   margin-top: 0.5em;
}
.itemOpt{
    margin-left: 1em;
    margin-right: 1em;
    padding: 0;
    border: 1px solid  #e0e0e0;
   border-radius:3px;
  
}
.item{
    
}
.labelclass{
  color: black;
}

.line{
  margin-top:0.5em;
}
.custom-button {
    width: 26px;
    color: #fff;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    background-color: #ee0a24;
    border-radius: 100px;
}
</style>