<template>
<div>
<BaseInfo :baseInfo="$store.state.user.baseInfo" />
<vfrom @submit="onSubmit">
    <tabs v-model="active" scrollspy sticky ellipsis   color="black" background="#e0e0e0" >
       <tab v-for="(section,index) in quesstus.sections" :title="section.title" :key="index">    
         <Divider :style="{ color: '#e0e0e0', borderColor: '#e0e0e0', padding: '0 16px' }" ><h3  class="stitle">{{section.title}}</h3></Divider>
         <div v-for="(mdata,idx) in section.meteDatas"  :key="idx"  >
           <div v-if="mdata.type=='ITEM'"  >
             <RilinItem :item="mdata.item" :name="mdata.item.code+'_'+0" mtype="item"  :mdata="mdata" :gord="0" :data="quesdatas" ></RilinItem>
           </div>
           <div v-if="mdata.type=='GROUP'" >
             <RilinGroup :group="mdata.group" :mdata="mdata"  mtype="item" :gord="0" :data="quesdatas" ></RilinGroup>
           </div>
         </div>
    </tab>
</tabs>
 <div style="margin: 16px;">
    <Button v-show="showSubmit" round block type="info" native-type="submit">提交</Button>
  </div>
</vfrom> 
</div>
</template>
<script>
import { Tab, Tabs ,Form as vfrom,Button} from 'vant';
import {getquestus,getqueDatas,saveDatas} from '@/api/ques';
import RilinGroup from '@/components/RilinGroup';
import RilinItem from '@/components/RilinItem';
export default {
  name: 'wjdata',
  
  components: {
     Tab, Tabs,RilinGroup,RilinItem,vfrom,Button
  },
  data(){
      return {
        active:0,
        quesdatas:{},
        quesstus:[],
        show:false
      };
  },
  methods:{
   onSubmit(values){
      let data={};
      for(let key in values){
        if(typeof([])==typeof(values[key])){
            data[key]=values[key].join(",");
        }else{
          data[key]=values[key];
        }
      }
      console.info(data);
      saveDatas({mid:this.$route.params.mid},data).then((response) => {
        if(response.code==200){
             this.$toast('保存成功');
             if(window.JS2AndroidUtil){
                window.JS2AndroidUtil.clickAction('保存成功');
             }else{
               let query = this.$route.query;
               let code=query.code?query.code : this.getQueryString('code');
                if(code){
                   this.$router.push({path: '/success'});
                }else{
                   this.$router.push({path: '/queidx/'+this.$store.state.user.pid});
                }
             }
        }else{
           //this.$router.push({path: '/err'});
           this.$toast('保存出错请稍候再试');
        }
             
      });
     
   },
   showPopup(){
     this.show=true;
   }
  },created(){
      getqueDatas({mid:this.$route.params.mid}).then((response) => {
                    this.quesdatas=response.data;
                    //console.info("查询数据",response.data);
                     getquestus({mid:this.$route.params.mid}).then((response) => {
                        this.quesstus=response.data;
                 }); 
              });
  },
  computed:{
      datas(){
        console.info("计算数据");
        return this.quesdatas;
      },
      showSubmit(){
        let query = this.$route.query;
        let code=query.code?query.code : this.getQueryString('code');
        if(this.$store.state.user.pid || code){
          return true;
        }
        return false;
      }
  },destroyed(){
    //console.info("destroyed");
    this.$store.commit('updateCheckMap',{});
  }
}
</script>
<style scoped>
.stitle{
 color: black;
  margin-left: 0.5em;
}
</style>