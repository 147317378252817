<template>
   <div class="group"  v-if="show">
       <div v-if="group.type != 'MULTIPLE'">
        <div   v-for="(gmdata,idx) in group.groupMeteDatas"  :key="idx" >
           <div v-if="gmdata.type=='ITEM'"  >
             <RilinItem  :item="gmdata.item"  :name="gmdata.item.code+'_'+gord" mtype="group"  :gmdata="gmdata" :gord="gord" :data="data" ></RilinItem>
           </div>
           <div v-if="gmdata.type=='GROUP'" >
              <div  class="cgroup">
                  <RilinGroup :group="gmdata.group"  :gmdata="gmdata"  mtype="group" :gord="gord" :data="data" ></RilinGroup>
              </div>           
           </div>
         </div>
       </div>
      <div v-if="group.type == 'MULTIPLE'">
          <span   class="gtitle" >{{group.name}}<Icon name="add-square" class="sp" @click="addGroup" /><Icon name="delete" class="sp" @click="rmGroup" /></span>
          <div v-for="(i,gkey) in groups" :key="gkey" >
            <div v-show="i>1" >
               <div   v-for="(gmdata,idx) in group.groupMeteDatas"  :key="idx" >
                  <div v-if="gmdata.type=='ITEM'"  >
                    <RilinItem  :item="gmdata.item"  :name="gmdata.item.code+'_'+(i-1)" mtype="group"  :gmdata="gmdata" :gord="i-1" :data="data" ></RilinItem>
                  </div>
                  <div v-if="gmdata.type=='GROUP'" >
                      <div  class="cgroup">
                          <RilinGroup :group="gmdata.group"  :gmdata="gmdata"  mtype="group" :gord="i-1" :data="data" ></RilinGroup>
                      </div>           
                  </div>
             </div>
          </div> 
          </div>
          </div>
     </div>
</template>

<script>
import {   Icon} from 'vant';
import RilinItem from '@/components/RilinItem';

export default {
  name: 'RilinGroup',
  components:{
    RilinItem,Icon
  },
  props:{
      group:{
          type: Object,
          required:true
      },
      mdata:{
        type: Object,
      },
      gmdata:{
        type: Object,
      },
      mtype:{
        type:String
      },
      gord:{
        type:Number
      },
      data:{
        type:Object
      }
  },
  created(){
     //this.midx=this.queryMid(this.group);
    // console.info('create',this.midx);
  },
  data() {
    return {
      show:false,
      midx:1
    }
  },
  computed:{
    groups(){ 
     // console.info("groups",this.gord+this.midx+1);
      return this.gord+this.midx+1;
    }
  },
  methods: { 
    queryMid(g){
      let cgmdatas=g.groupMeteDatas;
      for(let i=0; i<cgmdatas.length;i++ ){
        let cgmdata=cgmdatas[i];
        //console.info('cgmdata',cgmdata);
          if(cgmdata.type=='ITEM'){
            console.info('item',cgmdata.item.code);
            if(this.data[cgmdata.item.code] && this.data[cgmdata.item.code].idxs){
              console.info('return',this.data[cgmdata.item.code]);
               return this.data[cgmdata.item.code].idxs[this.data[cgmdata.item.code].idxs.length-1];
            }
          }
          if(cgmdata.type=='GROUP'){
            let r=this.queryMid(cgmdata.group);
            if(r){
              return r;
            }
          }
      }
    },
    addGroup(){
      this.midx=this.midx+1;
    },
    rmGroup(){
       if(this.midx>1){
           this.midx=this.midx-1;
       }
    },
    triggerby(){
       let gmdata=this.gmdata;
       let ord=this.midx-1; 
       if(this.mtype=='item'){
         this.show=true;
       }else{
            if(gmdata.triggeredBy){
                let type=gmdata.type.toLowerCase();
                let code = type=='item'?gmdata.itemCode:gmdata.groupItemCode//t.split("[")[0];
                let tval=gmdata.triggeredBy.match(/\[.+\]/)[0].replace("[", "").replace("]", "");
               // console.info("注册触发项",gmdata.groupCode+'_'+type+'_'+code+'_'+ord);
                this.$Event.$on(gmdata.groupCode+'_'+type+'_'+code+'_'+ord,obj=>{
                       //console.info('触发操作-->'+obj,tval);
                      if('string'==typeof(obj)){
                        if(obj==tval){
                            this.show=true;
                        }else{
                           this.show=false;
                        }
                      }else{
                          if(obj.indexOf(tval)>-1 ){
                           // console.info("包含"+tval);
                            this.show=true;
                          }else{
                            // console.info("不包含"+tval);
                             this.show=false;
                          }
                      }
                      
                  });
            }else{
                this.show=true;
            }
       }
    }
  },
   mounted() {//在模板编译完成后执行
          this.triggerby();
              let m=this.$store.state.user.checkMap;
             if(this.group.type == 'MULTIPLE'){
                  //console.info("监听",this.group.code+'_mord');
                  this.$Event.$on(this.group.code+'_mord',i=>{
                     if(!m['run_'+this.group.code+'_mord_'+i]){
                          m['run_'+this.group.code+'_mord_'+i]=true;
                           //console.info("执行",this.group.code+'_mord',i);
                           if(i>this.midx){
                              this.midx=i;
                          }
                     }
                  });
               }else if(this.mtype=='group'){//不是MULTIPLE 要负责传递
                  //console.info("监听",this.gmdata.groupCode+'_mord');
                  this.$Event.$on(this.gmdata.groupCode+'_mord',i=>{
                    if(!m['mid_'+this.gmdata.groupCode+'_mord_'+i]){
                      m['mid_'+this.gmdata.groupCode+'_mord_'+i]=true;
                      //console.info('传递',this.gmdata.groupCode+'_mord',i);
                      this.$Event.$emit(this.gmdata.groupCode+'_mord',i);
                    }
                  });
               }
    },
}
</script>

<style  scoped>
.group{
 
}
.gtitle{
  margin-left: 0.5em;
}
.sp{
  margin-left: 1em;
}
</style>